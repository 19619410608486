import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  Form,
  FormGroup,
  Label,
  FormText,
  UncontrolledTooltip
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import TabPartners from "./tabPartners";
import TabDocuments from "./tabDocuments";
import TabSurveys from "./tabSurveys";
import TabBankCards from "./tabBankCards/tabBankCards";
import TabTransact from "./tabTransacts/tabTransact";
import TabMovements from "./tabMovements";
import TabPayments from "./tabPayments";
import TabReactivation from "./tabReactivation";
import TabPartnersIngress from "./tabPartnersIngress";
import TabMessages from "./tabMessages/index";
import TabHistoryAutCharge from "./tabHistoryAutCharge";
import TabAnnuitiesQuote from "./tabAnnuitiesQuote";
import TabDiscountCodes from "./tabDiscountCodes";
import moment from "moment";
import {
  parse as parseQueryParams,
  stringify as objectToQueryParams
} from "query-string";
import PartnerSelector from "../../../People/containers/PartnerSelector";
import {
  getPartnerMembershipByPartnerId,
  getPartnerMembershipDocumentInstancesRequest,
  updatePartnerMembershipStatus,
  putDesasociarConvenio,
  getCrm1Benefits,
  getComprobarConvenio,
  getPartnerMembershipVacationFees,
  getPartnerMembershipMaintenacesDetailReport,
  getPartnerMembershipReferred,
  getPartnerMembershipAvailableReferredCodes,
  createPartnerMembershipReferredCodes,
  getExtranjerosLicenciaActual,
  deleteExtranjerosLicenciaActual,
  getExtranjeros,
  getPartnersClubAccessInfo,
  getPartnerMembershipFitKidzEvidence,
  getDiscountCodesFitkidz,
  getPartnerMembershipPreviousMaintenances
} from "../../actions/partners";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheckCircle,
  faEdit,
  faPen,
  faPencilAlt,
  faSave,
  faShoppingCart
} from "@fortawesome/free-solid-svg-icons";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import { handleRequestError } from "../../../utils/helpers";
import { Props, State } from "./interfaces";
import {
  PARTNER_MEMB_ACTIVE_STATUS,
  PARTNER_MEMB_CANCELLED_STATUS,
  PARTNER_MEMB_INACTIVE_STATUS,
  PARTNER_MEMB_PASIVE_STATUS,
  PARTNER_MEMB_TRASPASSED_STATUS
} from "../../../../src/utils/constants";
import { parseArrayToDropdownOptions } from "../../../../src/utils/helpers";

import { getBanksRequest, getSellersCatalog } from "../../actions/catalogs";
import {
  getPaymentMethodsRequest,
  getDiscountCodes,
  getFutureChargesQuoteRequest,
  getMessagesRequest,
  searchMovementsByClubAndMembershipRequest,
  getActualQuoteRequest
} from "../../actions/partners";
import { getMaintenancesAvailableForAnnuities } from "../../actions/annuities";
import {
  getCardTypesRequest,
  getSurveyTypesRequest
} from "../../actions/catalogs";
import { getPartnerMembershipInvoicesToReturnRequest } from "../../actions/billing";
import { getHistAutChargesRequest } from "../../actions/products";
import DatePicker from "../common/DatePicker";
import SideActionButton from "../common/sideActionButton";
import TabHistoryCrm1 from "./tabHistoryCrm1";
import TabBenefitsCrm1 from "./tabBenefitsCrm1";

// TODO: REDUX
import axios from "axios";
import constants from "../../../utils/constants";
import TabBinnacle from "./tabBinnacle";
import TabDescuentosDirectos from "./tabDescuentosDirectos";
import ConfirmModal from "../common/confirmModal/confirmModal";
import TabVacationFees from "./tabVacationFees";
import TabMaintenancesDetailReport from "./tabMaintenancesDetail";
import TabReferred from "./tabReferred";
import { TabLicenciaExtranjeros } from "./tabLicenciaExtranjeros";
import { getMantenimientosAsociadosNombres } from "../../actions/convenios";
import ModalFrame from "../../../toolkit/modalFrame";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import TabFitKidz from "./tabFitKidz";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";
import PromotionAssociationModal from "./promotionAssociationModal/promotionAssociationModal";
import AlternateClubAssociationModal from "./alternateClubAssociationModal/alternateClubAssociationModal";
import { getPromotionsFilteredRequest } from "../../actions/promotions";
import BasicSpinner from "../../../toolkit/basicSpinner";

const renderChangeStatusButton = (
  idMembresiaSocio,
  newStatus,
  buttonId,
  tooltipTitle,
  icon,
  f
) => {
  return (
    <a
      id={buttonId}
      className="ml-3"
      style={{ cursor: "pointer" }}
      onClick={e => {
        e.preventDefault();
        updatePartnerMembershipStatus(idMembresiaSocio, newStatus)
          .then(() => {
            f();
          })
          .catch(handleRequestError);
      }}
    >
      <FontAwesomeIcon icon={icon} />
      <UncontrolledTooltip placement="top" target={buttonId}>
        {tooltipTitle}
      </UncontrolledTooltip>
    </a>
  );
};

const ESTATUS_MEMBRESIA_BLOQUEO = [
  PARTNER_MEMB_CANCELLED_STATUS,
  PARTNER_MEMB_TRASPASSED_STATUS
];

const ESTATUS_MEMBRESIA_BLOQUEO_REACTIVACION = [
  PARTNER_MEMB_PASIVE_STATUS,
  PARTNER_MEMB_INACTIVE_STATUS
];

class Partners extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: "",
      idPublico: ""
    },
    idMembresiaSocio: "",
    activeTab: "1",
    partner: null,
    users: [],
    documentInstances: [],

    banks: [],
    paymentMethods: [],
    cardTypes: [],
    surveyTypes: [],

    annuitiesAvailableMaintenances: [],
    discountCodes: [],
    invoicesToReturn: [],
    listHistoryAutCharges: [],
    futureChargesQuote: null,
    messagesByMembresia: [],
    movementsList: [],
    actualQuote: null,
    currentAgreements: [],
    partnerMembershipDocumentInstances: [],
    fechaAdquisicion: null as any,
    crm1BenefitsList: [],
    convenioMessage: null,
    vacationFees: [],
    maintenancesDetail: [],
    maintenancesDetailSearching: false,
    referredDetail: [],
    referredCodesDetail: [],
    previousMaintenancesList: [],

    // Licencias de extranjeros
    licencia: null,
    licenciaCancelacion: false,
    extranjeros: [],

    partnersClubAccessInfo: [],

    mttoConvenio: [],
    modalMttoConvenio: false,

    fitKidzEvidencesList: [],
    fitKidzDiscountsCodesList: [],

    isPromotionAssociationModalOpen: false,
    isAlternateClubAssociationModalOpen: false,
    allowedPromotions: [],

    searching: false,
    modalMensajeAlerta: false,
    modalMensajeAlertaMessage: "",
    modalMensajeAlertaTitulo: ""
  };

  searchParner = filters => {
    this.setState({
      filters: { ...filters }
    });

    this.props
      .getPartner(filters)
      .then(async ({ value: { data } }) => {
        this.props.history.push(
          `/membresias-socios/${data.idMembresiaSocio}?${objectToQueryParams(
            filters
          )}`
        );
        this.setState({ partner: data, searching: false });

        try {
          const responseLicencia = await getExtranjerosLicenciaActual(
            data.idMembresiaSocio
          );
          if (responseLicencia.data && responseLicencia.data.fechaInicio) {
            this.setState({
              licencia: responseLicencia.data
            });
          } else {
            this.setState({
              licencia: null
            });
          }

          const responseExtranjeros = await getExtranjeros(
            data.idMembresiaSocio
          );
          if (responseExtranjeros.data) {
            this.setState({
              extranjeros: responseExtranjeros.data
            });
          }

          if (data.idConvenio) {
            const resp = await getMantenimientosAsociadosNombres(
              data.idConvenio
            );
            this.setState({ mttoConvenio: resp.data });

            await getComprobarConvenio(data.idMembresiaSocio);
            this.setState({
              convenioMessage: null
            });
          }

          //this.getInitialPartnerMembershipMessages();
        } catch (error) {
          this.setState({
            convenioMessage:
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null
          });

          this.setState({
            licencia: null
          });
        }
      })
      .catch(error => {
        this.setState({ searching: false });
      });
  };

  refreshPartner = () => {
    const { idPublico, idClub } = parseQueryParams(this.props.location.search);

    if (idPublico && idClub) {
      this.searchParner({
        idPublico,
        idClub
      });
    }
  };

  componentDidMount() {
    const { idPublico, idClub } = parseQueryParams(this.props.location.search);

    if (idPublico && idClub) {
      this.searchParner({
        idPublico,
        idClub
      });
    } else {
      const { filters } = this.state;
      if (
        !filters.idClub &&
        this.props.userContext &&
        !!this.props.userContext.clubSessionId
      ) {
        filters.idClub = this.props.userContext.clubSessionId;
        this.setState({ filters: { ...filters } });
      }
    }
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.agreements != this.props.agreements) {
      this.setState({
        currentAgreements: this.props.agreements
      });
    }
    if (!prevProps.partnerCreated && this.props.partnerCreated) {
      this.refreshPartner();
    }
  }

  search = () => {
    infoNotification("Buscando membresía...");
    this.setState({ searching: true });
    this.clearForNewSearch();
    this.toggleTab("1");
    this.searchParner(this.state.filters);
  };

  clearForNewSearch = () => {
    this.setState({
      paymentMethods: [],
      annuitiesAvailableMaintenances: [],
      discountCodes: [],
      invoicesToReturn: [],
      listHistoryAutCharges: [],
      futureChargesQuote: null,
      messagesByMembresia: [],
      movementsList: [],
      actualQuote: null,
      currentAgreements: [],
      partnerMembershipDocumentInstances: [],
      crm1BenefitsList: [],
      vacationFees: [],
      maintenancesDetail: [],
      maintenancesDetailSearching: false,
      referredDetail: [],
      referredCodesDetail: [],
      previousMaintenancesList: [],
      partnersClubAccessInfo: [],
      fitKidzEvidencesList: [],
      fitKidzDiscountsCodesList: []
    });
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  personChanged = person => {
    const idPersona = !!person ? person.personaId : "";
    getPartnerMembershipByPartnerId(idPersona)
      .then(({ data }) => {
        const { filters } = this.state;
        filters.idClub = data.idClubBase;
        filters.idPublico = data.idPublico;

        this.setState({ filters });
        this.search();
      })
      .catch(handleRequestError);
  };

  //-----------------------------------------------------------------------------
  getBanksCatalog = () => {
    const { banks } = this.state;
    if (banks == null || banks.length == 0) {
      getBanksRequest()
        .then(({ data }) => {
          this.setState({ banks: [...data] });
        })
        .catch(handleRequestError);
    }
  };

  getPaymentMethods = () => {
    const { paymentMethods } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    // if (paymentMethods == null || paymentMethods.length == 0) {
    getPaymentMethodsRequest(idMembresiaSocio)
      .then(({ data }) => {
        this.setState({ paymentMethods: [...data] });
      })
      .catch(handleRequestError);
    // }
  };

  getMembershipDiscountCodes = () => {
    const { discountCodes } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    if (discountCodes == null || discountCodes.length == 0) {
      getDiscountCodes(idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ discountCodes: data });
        })
        .catch(handleRequestError);
    }
  };

  getAnnuitiesAvailableMaintenaces = () => {
    const { annuitiesAvailableMaintenances } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    if (
      annuitiesAvailableMaintenances == null ||
      annuitiesAvailableMaintenances.length == 0
    ) {
      getMaintenancesAvailableForAnnuities(idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ annuitiesAvailableMaintenances: [...data] });
        })
        .catch(handleRequestError);
    }
  };

  getAnnuitiesUsers = () => {
    getSellersCatalog()
      .then(({ data }) => {
        this.setState({ users: data });
      })
      .catch(handleRequestError);
  };

  getCardTypes = () => {
    const { cardTypes } = this.state;

    if (cardTypes == null || cardTypes.length == 0) {
      getCardTypesRequest()
        .then(({ data }) => {
          this.setState({ cardTypes: data });
        })
        .catch(handleRequestError);
    }
  };

  getInvoicesToReturn = () => {
    const { invoicesToReturn } = this.state;

    if (invoicesToReturn == null || invoicesToReturn.length == 0) {
      const { idPublico, idClub } = parseQueryParams(
        this.props.location.search
      );

      getPartnerMembershipInvoicesToReturnRequest(idClub, idPublico)
        .then(({ data }) => {
          this.setState({ invoicesToReturn: data });
        })
        .catch(handleRequestError);
    }
  };

  getAutomaticChargesRecord = () => {
    const { listHistoryAutCharges } = this.state;

    if (listHistoryAutCharges == null || listHistoryAutCharges.length == 0) {
      const { idPublico, idClub } = parseQueryParams(
        this.props.location.search
      );

      getHistAutChargesRequest(idClub, idPublico)
        .then(({ data }) => {
          this.setState({ listHistoryAutCharges: data });
        })
        .catch(handleRequestError);
    }
  };

  getFutureChargesQuotationTabData = () => {
    const { futureChargesQuote } = this.state;

    if (futureChargesQuote == null) {
      const { idPublico, idClub } = parseQueryParams(
        this.props.location.search
      );

      getFutureChargesQuoteRequest(idPublico, idClub)
        .then(({ data }) => {
          this.setState({ futureChargesQuote: data });
        })
        .catch(handleRequestError);
    }
  };

  getInitialPartnerMembershipMessages = () => {
    const { messagesByMembresia } = this.state;

    if (messagesByMembresia == null || messagesByMembresia.length == 0) {
      const { idMembresiaSocio } = this.props.partner;

      getMessagesRequest(idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ messagesByMembresia: data });

          if ((data || []).length > 0) {
            const messagesAlert = data.filter(x => !!x.alerta && !!x.activo);
            if (messagesAlert.length > 0) {
              this.setState({
                modalMensajeAlerta: true,
                modalMensajeAlertaMessage: messagesAlert[0].descripcion,
                modalMensajeAlertaTitulo: messagesAlert[0].titulo
              });
            }
          }
        })
        .catch(handleRequestError);
    }
  };

  getPartnerMembershipMessages = () => {
    const { messagesByMembresia } = this.state;

    if (messagesByMembresia == null || messagesByMembresia.length == 0) {
      const { idMembresiaSocio } = this.props.partner;

      getMessagesRequest(idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ messagesByMembresia: data });
        })
        .catch(handleRequestError);
    }
  };

  getMovements = () => {
    const { movementsList } = this.state;

    if (movementsList == null || movementsList.length == 0) {
      const {
        idPublico: membresiaSocioIdPublico,
        idClub: clubId
      } = parseQueryParams(this.props.location.search);

      searchMovementsByClubAndMembershipRequest({
        clubId,
        membresiaSocioIdPublico
      })
        .then(({ data }) => {
          this.setState({ movementsList: data });
        })
        .catch(handleRequestError);
    }
  };

  getCurrentQuote = () => {
    const { actualQuote } = this.state;

    if (actualQuote == null) {
      const { idPublico, idClub } = parseQueryParams(
        this.props.location.search
      );

      getActualQuoteRequest(idPublico, idClub)
        .then(({ data }) => {
          this.setState({ actualQuote: data });
        })
        .catch(handleRequestError);
    }
  };

  getCurrentAgreements = () => {
    const { currentAgreements } = this.state;

    if (currentAgreements == null || currentAgreements.length == 0) {
      this.props.getEmpresasActivas({
        clubId: this.props.partner.idClubBase,
        mantenimientoId: this.props.partner.idMantenimiento,
        membresiaId: this.props.partner.idMembresia
      });
    }
  };

  getSurveyTypes = () => {
    const { surveyTypes } = this.state;

    if (surveyTypes == null || surveyTypes.length == 0) {
      getSurveyTypesRequest()
        .then(({ data }) => {
          this.setState({ surveyTypes: data });
        })
        .catch(handleRequestError);
    }
  };

  getPartnerMembershipDocumentInstances = () => {
    getPartnerMembershipDocumentInstancesRequest(
      this.props.partner.idMembresiaSocio
    )
      .then(({ data }) => {
        this.setState({ partnerMembershipDocumentInstances: data });
      })
      .catch(handleRequestError);
  };

  getCrm1Benefits = () => {
    const { crm1BenefitsList } = this.state;

    if (crm1BenefitsList == null || crm1BenefitsList.length == 0) {
      getCrm1Benefits(this.props.partner.idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ crm1BenefitsList: data });
        })
        .catch(handleRequestError);
    }
  };

  getMembershipVacationFees = () => {
    const { vacationFees } = this.state;

    if (vacationFees == null || vacationFees.length == 0) {
      getPartnerMembershipVacationFees(this.props.partner.idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ vacationFees: data });
        })
        .catch(handleRequestError);
    }
  };

  getMembershipMaintenancesDetail = () => {
    const { maintenancesDetail } = this.state;

    if (maintenancesDetail == null || maintenancesDetail.length == 0) {
      this.setState({ maintenancesDetailSearching: true });

      getPartnerMembershipMaintenacesDetailReport(
        this.props.partner.idMembresiaSocio
      )
        .then(({ data }) => {
          const result = {};
          (data || []).forEach(item => {
            const { personaId, personaNombre } = item;
            if (!result[personaId]) {
              result[personaId] = {
                personaId,
                personaNombre,
                mantenimientos: [item]
              };
            } else {
              result[personaId].mantenimientos = [
                ...result[personaId].mantenimientos,
                item
              ];
            }
          });

          const partnersMaintenances = [];

          Object.keys(result).forEach(key => {
            partnersMaintenances.push(result[key]);
          });

          this.setState({
            maintenancesDetail: partnersMaintenances,
            maintenancesDetailSearching: false
          });
        })
        .catch(handleRequestError);
    }
  };

  getMembershipReferredDetail = () => {
    const { referredDetail } = this.state;

    if (referredDetail == null || referredDetail.length == 0) {
      getPartnerMembershipReferred(this.props.partner.idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ referredDetail: data });
        })
        .catch(handleRequestError);
    }
  };

  getMembershipReferredCodesDetail = () => {
    const { referredCodesDetail } = this.state;

    if (referredCodesDetail == null || referredCodesDetail.length == 0) {
      getPartnerMembershipAvailableReferredCodes(
        this.props.partner.idMembresiaSocio
      )
        .then(({ data }) => {
          this.setState({ referredCodesDetail: data });
        })
        .catch(handleRequestError);
    }
  };

  createMembershipReferredCodes = () => {
    createPartnerMembershipReferredCodes(this.props.partner.idMembresiaSocio)
      .then(async () => {
        await this.invalidateStateValue("referredCodesDetail", []);
        await this.getMembershipReferredCodesDetail();
      })
      .catch(handleRequestError);
  };

  getPartnersClubAccessInfoXX = () => {
    const { partnersClubAccessInfo } = this.state;

    if (partnersClubAccessInfo == null || partnersClubAccessInfo.length == 0) {
      getPartnersClubAccessInfo(this.props.partner.idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ partnersClubAccessInfo: data });
        })
        .catch(handleRequestError);
    }
  };

  getFitKidzEvidencesList = () => {
    const { fitKidzEvidencesList } = this.state;

    if (fitKidzEvidencesList == null || fitKidzEvidencesList.length == 0) {
      getPartnerMembershipFitKidzEvidence(this.props.partner.idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ fitKidzEvidencesList: data });
        })
        .catch(handleRequestError);
    }
  };

  getFitKidzDiscountsCodesList = () => {
    const { fitKidzDiscountsCodesList } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    if (
      fitKidzDiscountsCodesList == null ||
      fitKidzDiscountsCodesList.length == 0
    ) {
      getDiscountCodesFitkidz(idMembresiaSocio)
        .then(({ data }) => {
          this.setState({ fitKidzDiscountsCodesList: data });
        })
        .catch(handleRequestError);
    }
  };

  invalidateStateValue = (fieldName, value) => {
    this.setState({ ...this.state, [fieldName]: value });
  };

  getOnlyMigratedClubs = () => {
    return (this.props.clubs || []).filter(c => !!c.migradoCrm2);
  };

  isMembershipBlocked = () => {
    return (
      !!this.props.partner &&
      ESTATUS_MEMBRESIA_BLOQUEO.includes(this.props.partner.estatusMembresia)
    );
  };

  isMembershipBlockedReactivacion = () => {
    return (
      !!this.props.partner &&
      ESTATUS_MEMBRESIA_BLOQUEO_REACTIVACION.includes(
        this.props.partner.estatusMembresia
      )
    );
  };

  getMembershipPreviousMaintenancesList = () => {
    const { previousMaintenancesList } = this.state;

    if ((previousMaintenancesList || []).length == 0) {
      getPartnerMembershipPreviousMaintenances(
        this.props.partner.idMembresiaSocio
      )
        .then(({ data }) => {
          this.setState({ previousMaintenancesList: data });
        })
        .catch(handleRequestError);
    }
  };

  render() {
    const hasNotifications =
      !!this.props.partner &&
      (this.props.partner.notificaciones || []).length > 0;

    return (
      <Container className="p-0 container" fluid>
        <ConfirmModal
          title="Cancelación de licencia"
          message="Se cancelará la licencia al momento y se generarán los cargos correspondientes de ser necesario. ¿Desea continuar?"
          isOpen={this.state.licenciaCancelacion}
          ok={async () => {
            try {
              await deleteExtranjerosLicenciaActual(
                this.props.partner.idMembresiaSocio
              );
              this.setState({
                licenciaCancelacion: false
              });

              this.refreshPartner();
            } catch (error) {
              errorNotification("No fue posible pausar la membresía");
            }
          }}
          cancel={() => {
            this.setState({
              licenciaCancelacion: false
            });
          }}
        />

        <ModalFrame
          title={`Oferta comercial para el convenio`}
          isOpen={this.state.modalMttoConvenio}
          toggle={() => this.setState({ modalMttoConvenio: false })}
          size="lg"
        >
          <>
            <Container>
              <div className="alert alert-info">
                Lista de mantenimientos disponiblesa para este convenio:
              </div>
              <Row>
                {this.state.mttoConvenio.map((c, i) => (
                  <Col key={"_mttoconv_" + i} sm={3} md={4}>
                    {c}
                  </Col>
                ))}
              </Row>
            </Container>
          </>
        </ModalFrame>

        <ModalFrame
          title={`${this.state.modalMensajeAlertaTitulo}`}
          isOpen={this.state.modalMensajeAlerta}
          toggle={() =>
            this.setState({
              modalMensajeAlerta: false,
              modalMensajeAlertaMessage: "",
              modalMensajeAlertaTitulo: ""
            })
          }
          size="lg"
        >
          <>
            <Container>
              <div className="">{this.state.modalMensajeAlertaMessage}</div>
            </Container>
          </>
        </ModalFrame>

        <PromotionAssociationModal
          isOpen={this.state.isPromotionAssociationModalOpen}
          partner={this.props.partner}
          promociones={this.state.allowedPromotions}
          cancel={() => {
            this.setState({ isPromotionAssociationModalOpen: false });
          }}
          success={() => {
            this.setState({ isPromotionAssociationModalOpen: false });
            this.refreshPartner();
          }}
        />

        <AlternateClubAssociationModal
          isOpen={this.state.isAlternateClubAssociationModalOpen}
          partner={this.props.partner}
          clubs={this.props.clubs}
          cancel={() => {
            this.setState({ isAlternateClubAssociationModalOpen: false });
          }}
          success={() => {
            this.setState({ isAlternateClubAssociationModalOpen: false });
            this.refreshPartner();
          }}
        />

        <Row>
          <Col md="4">
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>
                  Buscar por socio
                </p>
              </div>
            </div>
            <Row>
              <Col>
                <Col>
                  <PartnerSelector setPerson={this.personChanged} />
                </Col>
              </Col>
            </Row>
          </Col>
          <Col md="8">
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>
                  Buscar por club y número membresía
                </p>
              </div>
            </div>
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.search();
              }}
            >
              <Row>
                <Col>
                  <BaseInput
                    label="Club"
                    name="club"
                    id="club"
                    type="select"
                    value={this.state.filters.idClub}
                    onChange={this.onChangeFilter("idClub")}
                    options={parseArrayToDropdownOptions(
                      this.getOnlyMigratedClubs() || [],
                      "clubId",
                      "nombre"
                    )}
                  />
                </Col>
                <Col>
                  <BaseInput
                    label={"# Membresia"}
                    name={"idMembresiaSocio"}
                    id={"idMembresiaSocio"}
                    placeholder="Id Membresia Socio"
                    value={this.state.filters.idPublico}
                    size="sm"
                    onChange={this.onChangeFilter("idPublico")}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                    type="submit"
                    disabled={
                      !this.state.filters.idClub ||
                      !this.state.filters.idPublico ||
                      !!this.state.searching
                    }
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {this.props.partner &&
          this.props.partner.estatusMembresia ==
            PARTNER_MEMB_TRASPASSED_STATUS &&
          !!this.props.partner.descripcionTraspaso && (
            <div className="p-5 text-center">
              <h5>{this.props.partner.descripcionTraspaso}</h5>
            </div>
          )}

        {this.props.partner &&
          this.props.partner.estatusMembresia ==
            PARTNER_MEMB_CANCELLED_STATUS && (
            <div className="p-5 text-center">
              <h5>ELIMINADA</h5>
            </div>
          )}

        {!!this.state.licencia && (
          <div className="p-5 text-center">
            <h5>
              Esta membresía tiene activa una licencia de extranjero que
              finalizara en la fecha {this.state.licencia.fechaFin}
            </h5>
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton}`}
              type="button"
              onClick={e => {
                this.setState({ licenciaCancelacion: true });
              }}
            >
              Cancelar licencia
            </Button>
          </div>
        )}

        {!!this.state.searching && (
          <BasicSpinner
            message={"Consultando datos de la membresía socio"}
            loading={this.state.searching}
          />
        )}

        {this.props.partner &&
          !this.isMembershipBlocked() &&
          !this.state.licencia &&
          this.props.location.search != "" && (
            <Form>
              <Row className="row mt-3 mb-3">
                <Col>
                  <p className={`${s.primaryTitle} w-100 m-0`}>
                    Datos de la Membresía
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  sm={12}
                  md={hasNotifications ? 9 : 12}
                  lg={hasNotifications ? 10 : 12}
                >
                  <Row>
                    {!!this.props.partner.biclub && (
                      <Col sm={6} md={4} lg={3}>
                        <FormGroup>
                          <Label style={{ fontWeight: "bold" }}>
                            Club alterno:
                          </Label>
                          <FormText color="muted">
                            {this.getClubAlternoNombre()}
                            &nbsp;&nbsp;&nbsp;
                            {userHasPermission(
                              permissionCodes.PARTNER_MEMBERSHIP_ASSOCIATE_ALTERNATE_CLUB
                            ) &&
                              !!this.props.partner.biclub && (
                                <BaseButtonWithIconAndTooltip
                                  id={`associate_alternate_club_${this.props.partner.idMembresiaSocio}`}
                                  icon={faPen}
                                  tooltipTitle="Asociar club alterno"
                                  onClick={() => {
                                    this.setState({
                                      isAlternateClubAssociationModalOpen: true
                                    });
                                  }}
                                />
                              )}
                          </FormText>
                        </FormGroup>
                      </Col>
                    )}

                    <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>
                          Tipo de Membresía/Inscripción:
                        </Label>
                        <FormText color="muted">
                          {this.props.partner.nombreMembresia}
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>
                          Tipo de Mantenimiento:
                        </Label>
                        <FormText color="muted">
                          {this.props.partner.nombreMantenimiento}
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>
                          Propietario:
                        </Label>
                        <FormText color="muted">
                          {this.props.partner.nombrePropietario}
                          &nbsp;&nbsp;&nbsp;
                          <BaseButtonWithIconAndTooltip
                            id={`propietario_editar_${this.props.partner.idPropietario}`}
                            icon={faEdit}
                            tooltipTitle="Editar"
                            onClick={() => {
                              window.open(
                                `/socio/${this.props.partner.idPropietario}/detalle`,
                                "_blank"
                              );
                            }}
                          />
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>Titular:</Label>
                        <FormText color="muted">
                          {this.props.partner.nombrePersona}
                          &nbsp;&nbsp;&nbsp;
                          <BaseButtonWithIconAndTooltip
                            id={`titular_editar_${this.props.partner.idPersona}`}
                            icon={faEdit}
                            tooltipTitle="Editar"
                            onClick={() => {
                              window.open(
                                `/socio/${this.props.partner.idPersona}/detalle`,
                                "_blank"
                              );
                            }}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <BaseButtonWithIconAndTooltip
                            id={`titular_carrito_${this.props.partner.idPersona}`}
                            icon={faShoppingCart}
                            tooltipTitle=" Ir a carrito"
                            onClick={() => {
                              this.props.history.push(
                                `/productos/carrito/${this.props.partner.idPersona}`
                              );
                            }}
                          />
                        </FormText>
                      </FormGroup>
                    </Col>

                    <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>Convenio:</Label>
                        <FormText
                          color={
                            this.state.convenioMessage ? "danger" : "muted"
                          }
                        >
                          <span
                            id={"_btn_mtto_cnv"}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={() =>
                              this.setState({ modalMttoConvenio: true })
                            }
                          >
                            <FontAwesomeIcon icon={faEye} />
                            <UncontrolledTooltip
                              placement="top"
                              target={"_btn_mtto_cnv"}
                            >
                              Ver oferta comercial
                            </UncontrolledTooltip>
                          </span>
                          {this.props.partner.nombreConvenio}
                          {userHasPermission(
                            permissionCodes.PARTNER_MEMBERSHIP_DISASSOCIATE_AGREEMENT
                          ) &&
                            !!this.props.partner.idConvenio && (
                              <>
                                <div style={{ display: "inline-block" }}>
                                  <SideActionButton
                                    buttonId={`disassociate_agreement_membership_button`}
                                    tooltipTitle={"Desasociar convenio"}
                                    icon={faBan}
                                    onClick={async () => {
                                      await putDesasociarConvenio(
                                        this.props.partner.idMembresiaSocio
                                      );
                                      this.setState({
                                        convenioMessage: null
                                      });
                                    }}
                                    success={this.search}
                                    confirmModal={{
                                      title: "Desasociar convenio",
                                      message:
                                        "¿Desea desasociar el convenio de la membresía socio?"
                                    }}
                                  />
                                </div>
                              </>
                            )}
                        </FormText>
                        <div
                          style={{
                            color: "#dc3545",
                            fontSize: "14px",
                            marginTop: "4px"
                          }}
                        >
                          {this.state.convenioMessage}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>Promoción:</Label>
                        <FormText color="muted">
                          {this.props.partner.nombrePromocion}
                          &nbsp;&nbsp;&nbsp;
                          {!this.props.partner.nombrePromocion &&
                            userHasPermission(
                              permissionCodes.PARTNER_MEMBERSHIP_ASSOCIATE_PROMOTION
                            ) &&
                            !this.isMembershipBlocked() &&
                            !this.isMembershipBlockedReactivacion() && (
                              <BaseButtonWithIconAndTooltip
                                id={`associate_promotion_${this.props.partner.idMembresiaSocio}`}
                                icon={faCheckCircle}
                                tooltipTitle="Asociar promoción"
                                onClick={() => {
                                  const {
                                    idClubBase: club,
                                    idConvenio: convenio,
                                    idMantenimiento: mantenimiento,
                                    idMembresia: membresia
                                  } = this.props.partner;
                                  const params = {
                                    club,
                                    membresia,
                                    mantenimiento,
                                    convenio
                                  };

                                  getPromotionsFilteredRequest(params)
                                    .then(({ data }) => {
                                      if ((data || []).length <= 0) {
                                        errorNotification(
                                          "No existen promociones asociables para la membresía socio"
                                        );
                                      } else {
                                        this.setState({
                                          allowedPromotions: data || [],
                                          isPromotionAssociationModalOpen: true
                                        });
                                      }
                                    })
                                    .catch(handleRequestError);
                                }}
                              />
                            )}
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>Estatus:</Label>
                        <FormText color="muted">
                          {this.props.partner.estatusMembresia}
                          {userHasPermission(
                            permissionCodes.PARTNER_MEMBERSHIP_CHANGE_STATUS
                          ) &&
                            this.props.partner.estatusMembresia ==
                              PARTNER_MEMB_ACTIVE_STATUS &&
                            renderChangeStatusButton(
                              this.props.partner.idMembresiaSocio,
                              PARTNER_MEMB_INACTIVE_STATUS,
                              `inactivate_membership_button`,
                              "Desactivar membresía socio",
                              faBan,
                              this.search
                            )}
                          {userHasPermission(
                            permissionCodes.PARTNER_MEMBERSHIP_CHANGE_STATUS
                          ) &&
                            (this.props.partner.estatusMembresia ==
                              PARTNER_MEMB_INACTIVE_STATUS ||
                              this.props.partner.estatusMembresia ==
                                PARTNER_MEMB_PASIVE_STATUS) &&
                            renderChangeStatusButton(
                              this.props.partner.idMembresiaSocio,
                              PARTNER_MEMB_ACTIVE_STATUS,
                              `activate_membership_button`,
                              "Activar membresía socio",
                              faCheckCircle,
                              this.search
                            )}
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>
                          <span
                            style={{
                              color: this.props.partner.fechaAdquisicion
                                ? null
                                : "#A00"
                            }}
                          >
                            Fecha de Adquisición:
                            <br />
                          </span>
                        </Label>
                        {this.props.partner.fechaAdquisicion ? (
                          <FormText color="muted">
                            {moment(this.props.partner.fechaAdquisicion).format(
                              "DD/MM/YYYY"
                            )}
                          </FormText>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "inline-block",
                                maxWidth: "200px"
                              }}
                            >
                              <DatePicker
                                name="fechaNacimiento"
                                id="fechaNacimiento"
                                selected={this.state.fechaAdquisicion}
                                onChange={evt => {
                                  this.setState({
                                    fechaAdquisicion: evt
                                  });
                                }}
                              />
                            </div>
                            <a
                              id="fda0001"
                              className="ml-3"
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                color: "#888"
                              }}
                              onClick={async e => {
                                e.preventDefault();

                                if (!this.state.fechaAdquisicion) {
                                  errorNotification(
                                    "Debe seleccionar una fecha"
                                  );
                                  return;
                                }

                                infoNotification(
                                  "Guardando fecha de adquisición..."
                                );

                                try {
                                  await axios.post(
                                    `${constants.BASE_URL}/api/memberships/buy-date`,
                                    null,
                                    {
                                      withCredentials: true,
                                      params: {
                                        idMembresiaSocio: this.props.partner
                                          .idMembresiaSocio,
                                        fecha: moment(
                                          this.state.fechaAdquisicion
                                        ).format("YYYY-MM-DD")
                                      }
                                    }
                                  );

                                  successNotification(
                                    "Fecha de adquisición guardada correctamente"
                                  );
                                } catch (error) {
                                  const message =
                                    (error &&
                                      error.response &&
                                      error.response.data &&
                                      error.response.data.message) ||
                                    "Error al guardar la fecha de adquisición";
                                  errorNotification(message);
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faSave} />
                              <UncontrolledTooltip
                                placement="top"
                                target="fda0001"
                              >
                                Guardar fecha
                              </UncontrolledTooltip>
                            </a>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col sm={6} md={4} lg={3}>
                      <FormGroup>
                        <Label style={{ fontWeight: "bold" }}>
                          Intransferible:
                        </Label>
                        <FormText color="muted">
                          {this.props.partner.intransferible ? "Si" : "No"}
                        </FormText>
                      </FormGroup>
                    </Col> */}
                    <Col></Col>
                  </Row>
                </Col>
                {/*Notificaciones*/}
                {hasNotifications && (
                  <Col sm={12} md={3} lg={2}>
                    <Label style={{ fontWeight: "bold" }}>
                      Notificaciones:
                    </Label>
                    <div>
                      {((this.props.partner || {}).notificaciones || []).map(
                        notificacion => {
                          return (
                            <div
                              className={
                                "alert alert-" +
                                (this.props.partner.impedimentoAcceso
                                  ? "danger"
                                  : "warning")
                              }
                              style={{
                                fontSize: "0.8em",
                                fontWeight: this.props.partner.impedimentoAcceso
                                  ? "bold"
                                  : "normal"
                              }}
                            >
                              {notificacion}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        <hr />

        {this.props.partner && !!this.props.partner.descuentosAlDia && (
          <div className="m-5">
            Descuentos mantenimiento:
            <span className="ml-3">{this.props.partner.descuentosAlDia}</span>
          </div>
        )}

        {this.props.partner &&
          this.props.location.search != "" &&
          !this.state.licencia && (
            <Container
              className="w-100 p-0 m-0"
              style={{ height: "650px" }}
              xs={12}
            >
              <Nav className={s.tabs} tabs>
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_PARTNERS
                ) && (
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "1" ? "active" : ""
                      } ${s.tab}`}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      Socios
                    </NavLink>
                  </NavItem>
                )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_DOCUMENTS
                ) && (
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "2" ? "active" : ""
                      } ${s.tab}`}
                      onClick={() => {
                        this.toggleTab("2");
                        this.getPartnerMembershipDocumentInstances();
                      }}
                    >
                      Documentos
                    </NavLink>
                  </NavItem>
                )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_SURVEYS
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "3" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("3");
                          this.getSurveyTypes();
                        }}
                      >
                        Encuestas
                      </NavLink>
                    </NavItem>
                  )}

                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "5" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("5");
                          this.getPaymentMethods();
                        }}
                      >
                        Tokenización
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM
                ) &&
                  !this.isMembershipBlocked() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "6" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("6");
                          this.getCurrentQuote();
                          this.getCurrentAgreements();
                          this.getMembershipPreviousMaintenancesList();
                        }}
                      >
                        Trámites
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS
                ) && (
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "7" ? "active" : ""
                      } ${s.tab}`}
                      onClick={() => {
                        this.toggleTab("7");
                        this.getMovements();
                      }}
                    >
                      Movimientos
                    </NavLink>
                  </NavItem>
                )}

                {/*REQUERIMIENTO: REPORTE DETALLE MANTENIMIENTOS*/}
                {
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "21" ? "active" : ""
                      } ${s.tab}`}
                      onClick={() => {
                        this.toggleTab("21");
                        this.getMembershipMaintenancesDetail();
                      }}
                    >
                      Mantenimientos
                    </NavLink>
                  </NavItem>
                }

                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MESSAGES
                ) &&
                  !this.isMembershipBlocked() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "8" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("8");
                          this.getPartnerMembershipMessages();
                        }}
                      >
                        Mensajes
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_ADE
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "9" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("9");
                          this.getFutureChargesQuotationTabData();
                        }}
                      >
                        Adelantados
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_REACTIVATION
                ) &&
                  !this.isMembershipBlocked() &&
                  this.props.partner &&
                  this.props.partner.estatusMembresia != "ACTIVA" && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "10" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("10");
                        }}
                      >
                        Reactivación
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_ACCESS
                ) &&
                  !this.isMembershipBlocked() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "11" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("11");
                          this.getPartnersClubAccessInfoXX();
                        }}
                      >
                        Accesos
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_CAT_HIST
                ) &&
                  !this.isMembershipBlocked() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "12" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("12");
                          this.getAutomaticChargesRecord();
                        }}
                      >
                        Histórico CAT
                      </NavLink>
                    </NavItem>
                  )}
                {/*userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_DEVOL
                ) && (
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "13" ? "active" : ""
                      } ${s.tab}`}
                      onClick={() => {
                        this.toggleTab("13");
                        this.getBanksCatalog();
                        this.getCardTypes();
                        this.getInvoicesToReturn();
                      }}
                    >
                      Devoluciones
                    </NavLink>
                  </NavItem>
                )*/}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_ANNUITIES
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "14" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("14");
                          this.getAnnuitiesAvailableMaintenaces();
                          this.getAnnuitiesUsers();
                        }}
                      >
                        Anualidades
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "15" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("15");
                          this.getMembershipDiscountCodes();
                        }}
                      >
                        Códigos de descuento
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "19" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("19");
                        }}
                      >
                        Descuentos directos
                      </NavLink>
                    </NavItem>
                  )}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS
                ) &&
                  !this.isMembershipBlocked() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "16" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("16");
                        }}
                      >
                        Historial CRM1
                      </NavLink>
                    </NavItem>
                  )}

                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS
                ) &&
                  !this.isMembershipBlocked() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "17" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("17");
                          this.getCrm1Benefits();
                        }}
                      >
                        Beneficios CRM1
                      </NavLink>
                    </NavItem>
                  )}

                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_BINNACLE
                ) && (
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "18" ? "active" : ""
                      } ${s.tab}`}
                      onClick={() => {
                        this.toggleTab("18");
                      }}
                    >
                      Bitácora
                    </NavLink>
                  </NavItem>
                )}

                {/*REQUERIMIENTO: CUOTA VACACIONAL*/}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_VACATION_FEE
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "20" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("20");
                          this.getMembershipVacationFees();
                        }}
                      >
                        Cuotas vacacionales
                      </NavLink>
                    </NavItem>
                  )}

                {/*REQUERIMIENTO: REFERIDOS*/}
                {!this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "22" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("22");
                          this.getMembershipReferredCodesDetail();
                          this.getMembershipReferredDetail();
                        }}
                      >
                        Referidos
                      </NavLink>
                    </NavItem>
                  )}

                {/*REQUERIMIENTO: LICENCIA DE EXTRANJEROS*/}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_LICENCIA_EXTRANJERO
                ) &&
                  !this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() &&
                  this.state.extranjeros &&
                  !!this.state.extranjeros.length && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "23" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("23");
                        }}
                      >
                        Licencia extranjeros
                      </NavLink>
                    </NavItem>
                  )}

                {!this.isMembershipBlocked() &&
                  !this.isMembershipBlockedReactivacion() &&
                  !!this.props.partner.esFitKidz && (
                    <NavItem>
                      <NavLink
                        className={`${
                          this.state.activeTab === "24" ? "active" : ""
                        } ${s.tab}`}
                        onClick={() => {
                          this.toggleTab("24");
                          this.getFitKidzEvidencesList();
                          this.getFitKidzDiscountsCodesList();
                        }}
                      >
                        FitKidz
                      </NavLink>
                    </NavItem>
                  )}
              </Nav>

              <TabContent className="h-100" activeTab={this.state.activeTab}>
                <div></div>

                {/*SOCIOS*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_PARTNERS
                ) &&
                  this.props.partner &&
                  this.props.partnersMembership && (
                    <TabPartners
                      history={this.props.history}
                      partner={this.props.partner}
                      filters={this.state.filters}
                      partnersMembership={this.props.partnersMembership}
                      descripcionTraspaso={
                        this.props.partner.descripcionTraspaso
                      }
                      membershipTypesMembers={this.props.membershipTypesMembers}
                      toggleAddPartner={this.props.toggleAddPartner}
                      toggleAddFreePartner={this.props.toggleAddFreePartner}
                      toggleChangeFreePartner={
                        this.props.toggleChangeFreePartner
                      }
                      toggleAddFreeChildPartner={
                        this.props.toggleAddFreeChildPartner
                      }
                      getMembershipMembers={this.props.getMembershipMembers}
                      updatePhoto={this.props.updatePhoto}
                      updatePawPrint={this.props.updatePawPrint}
                      accessRegister={this.props.accessRegister}
                      getPartnersMembership={this.props.getPartnersMembership}
                      toggleDeletePartner={this.props.toggleDeletePartner}
                      titularUpdated={() => {
                        this.search();
                      }}
                      invalidateStateValue={this.invalidateStateValue}
                      executePartnerMembershipPartners={
                        this.props.executePartnerMembershipPartners
                      }
                      refreshPartner={this.refreshPartner}
                      membershipBlocked={this.isMembershipBlocked()}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                    />
                  )}

                {/*DOCUMENTOS*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_DOCUMENTS
                ) &&
                  this.props.partner &&
                  this.props.partnersMembership && (
                    <TabDocuments
                      partner={this.props.partner}
                      activeTab={this.state.activeTab}
                      descripcionTraspaso={
                        this.props.partner.descripcionTraspaso
                      }
                      membershipBlocked={this.isMembershipBlocked()}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                    />
                  )}

                {/*ENCUESTAS*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_SURVEYS
                ) &&
                  this.props.partner &&
                  this.state.surveyTypes &&
                  this.props.partnersMembership && (
                    <TabSurveys
                      members={this.props.partnersMembership}
                      surveyTypes={this.state.surveyTypes}
                      saveAnswers={this.props.saveAnswers}
                      idMembresiaSocio={this.props.partner.idMembresiaSocio}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                    />
                  )}

                {/*MEDIOS DE PAGO*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN
                ) &&
                  this.props.partner && (
                    <TabBankCards
                      partner={this.props.partner}
                      idMembresiaSocio={this.props.partner.idMembresiaSocio}
                      paymentMethods={this.state.paymentMethods}
                      clubs={this.props.clubs}
                      getPaymentMethods={this.getPaymentMethods}
                      deleteBankCard={this.props.deleteBankCard}
                      refreshPartner={this.refreshPartner}
                      invalidateStateValue={this.invalidateStateValue}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                    />
                  )}

                {/*TRAMITES*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM
                ) &&
                  this.props.partner && (
                    <TabTransact
                      activeTab={this.state.activeTab}
                      partner={this.props.partner}
                      quotations={this.props.quotations}
                      match={this.props.match}
                      history={this.props.history}
                      agreements={this.state.currentAgreements}
                      successfullSwitchMembership={
                        this.props.successfullSwitchMembership
                      }
                      actualQuote={this.state.actualQuote}
                      clubs={this.getOnlyMigratedClubs()}
                      getActualQuote={this.getCurrentQuote}
                      getQuotations={this.props.getQuotations}
                      rightsChange={this.props.rightsChange}
                      refreshPartner={this.refreshPartner}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                      previousMaintenancesList={
                        this.state.previousMaintenancesList || []
                      }
                    />
                  )}

                {/*MOVIMIENTOS*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS
                ) &&
                  this.props.partner && (
                    <TabMovements
                      movementsList={this.state.movementsList}
                      idMembresia={this.props.partner.idMembresia}
                      idMembresiaSocio={this.props.partner.idMembresiaSocio}
                      listHistoricalRebilling={
                        this.props.listHistoricalRebilling
                      }
                      sendMovementEmail={this.props.sendMovementEmail}
                      toggleBill={this.props.toggleBill}
                      toggleBillXml={this.props.toggleBillXml}
                      getMovements={this.getMovements}
                      historicalRebilling={this.props.historicalRebilling}
                      invalidateStateValue={this.invalidateStateValue}
                      refreshPartner={this.refreshPartner}
                      descripcionTraspaso={
                        this.props.partner.descripcionTraspaso
                      }
                      membershipBlocked={this.isMembershipBlocked()}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                    />
                  )}

                {/*MENSAJES*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MESSAGES
                ) &&
                  this.props.partner && (
                    <TabMessages
                      idMembresiaSocio={this.props.partner.idMembresiaSocio}
                      messagesByMembresia={this.state.messagesByMembresia}
                      partnersMembership={this.props.partnersMembership}
                      getMessages={this.getPartnerMembershipMessages}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                    />
                  )}

                {/*ADELANTADOS*/
                userHasPermission(permissionCodes.PARTNER_MEMBERSHIP_TAB_ADE) &&
                  this.props.partner && (
                    <TabPayments
                      partner={this.props.partner}
                      futureChargesQuote={this.state.futureChargesQuote}
                      invalidateStateValue={this.invalidateStateValue}
                      refreshPartner={this.refreshPartner}
                      membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                    />
                  )}

                {/*REACTIVACIONES*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_REACTIVATION
                ) &&
                  this.props.partner &&
                  this.props.partner.estatusMembresia != "ACTIVA" && (
                    <TabReactivation
                      history={this.props.history}
                      partner={this.props.partner}
                      quotations={this.props.quotations}
                      getQuotations={this.props.getQuotations}
                      activeTab={this.state.activeTab}
                    />
                  )}

                {/*ACCESOS*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_ACCESS
                ) && (
                  <TabPartnersIngress
                    partner={this.props.partner}
                    activeTab={this.state.activeTab}
                    partnersClubAccessInfo={this.state.partnersClubAccessInfo}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/*HISTORIAL CARGOS AUTOMATICOS*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_CAT_HIST
                ) && (
                  <TabHistoryAutCharge
                    listHistoryAutCharges={this.state.listHistoryAutCharges}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/*ANUALIDADES*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_ANNUITIES
                ) && (
                  <TabAnnuitiesQuote
                    annuitiesAvailableMaintenances={
                      this.state.annuitiesAvailableMaintenances
                    }
                    currentMembershipPartners={(
                      this.props.partnersMembership || []
                    ).filter(x => !x.esInvitadoGratis)}
                    currentMembershipConfiguration={this.props.partner}
                    users={this.state.users}
                    clubs={this.getOnlyMigratedClubs()}
                    history={this.props.history}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/*CODIGOS DE DESCUENTO*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES
                ) && (
                  <TabDiscountCodes
                    discountCodes={this.state.discountCodes}
                    idMembresiaSocio={this.props.partner.idMembresiaSocio}
                    invalidateStateValue={this.invalidateStateValue}
                    getMembershipDiscountCodes={this.getMembershipDiscountCodes}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/*DESCUENTOS DIRECTOS*/
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES
                ) && (
                  <TabDescuentosDirectos
                    partner={this.props.partner}
                    activeTab={this.state.activeTab}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/* HISTORIAL DE MOVIMIENTOS EN CRM1 */
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS
                ) && (
                  <TabHistoryCrm1
                    persons={this.props.partnersMembership}
                    activeTab={this.state.activeTab}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/* BITACORA */
                userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_BINNACLE
                ) && (
                  <TabBinnacle
                    idMembresiaSocio={this.props.partner.idMembresiaSocio}
                    idPersona={this.props.partner.idPersona}
                    activeTab={this.state.activeTab}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS
                ) && (
                  <TabBenefitsCrm1
                    crm1Benefits={this.state.crm1BenefitsList}
                    idMembresiaSocio={this.props.partner.idMembresiaSocio}
                    invalidateStateValue={this.invalidateStateValue}
                    getCrm1Benefits={this.getCrm1Benefits}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/*REQUERIMIENTO: CUOTA VACACIONAL*/}
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_VACATION_FEE
                ) && (
                  <TabVacationFees
                    vacationFees={this.state.vacationFees}
                    idMembresiaSocio={this.props.partner.idMembresiaSocio}
                    invalidateStateValue={this.invalidateStateValue}
                    getMembershipVacationFees={this.getMembershipVacationFees}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}

                {/*REQUERIMIENTO: REPORTE DETALLE MANTENIMIENTOS*/}
                <TabMaintenancesDetailReport
                  maintenancesDetail={this.state.maintenancesDetail}
                  idMembresiaSocio={this.props.partner.idMembresiaSocio}
                  searching={this.state.maintenancesDetailSearching}
                  invalidateStateValue={this.invalidateStateValue}
                  getMembershipMaintenancesDetail={
                    this.getMembershipMaintenancesDetail
                  }
                  membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                />

                {/*REQUERIMIENTO: REFERIDOS*/}
                <TabReferred
                  referredCodesDetail={this.state.referredCodesDetail}
                  referredDetail={this.state.referredDetail}
                  idMembresiaSocio={this.props.partner.idMembresiaSocio}
                  clubs={this.props.clubs}
                  invalidateStateValue={this.invalidateStateValue}
                  createReferredCodes={this.createMembershipReferredCodes}
                  membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  getMembershipReferredDetail={this.getMembershipReferredDetail}
                  getMembershipReferredCodesDetail={
                    this.getMembershipReferredCodesDetail
                  }
                />

                {/*REQUERIMIENTO: Licencia de extranjeros*/}
                <TabLicenciaExtranjeros
                  idMembresiaSocio={this.props.partner.idMembresiaSocio}
                  activeTab={this.state.activeTab}
                  membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                />

                {!!this.props.partner.esFitKidz && (
                  <TabFitKidz
                    idMembresiaSocio={this.props.partner.idMembresiaSocio}
                    activeTab={this.state.activeTab}
                    evidenceList={this.state.fitKidzEvidencesList}
                    getEvidences={this.getFitKidzEvidencesList}
                    fitKidzDiscountsCodesList={
                      this.state.fitKidzDiscountsCodesList
                    }
                    getDiscountCodes={this.getFitKidzDiscountsCodesList}
                    invalidateStateValue={this.invalidateStateValue}
                    refreshPartner={this.refreshPartner}
                    esFitKidzPorCaducar={this.props.partner.esFitKidzPorCaducar}
                    membershipBlockedReactivacion={this.isMembershipBlockedReactivacion()}
                  />
                )}
              </TabContent>
            </Container>
          )}
      </Container>
    );
  }
  getClubAlternoNombre() {
    return (
      (
        (this.props.clubs || []).find(
          club => club.clubId == (this.props.partner.idClubAlterno || 0)
        ) || {}
      ).nombre || ""
    );
  }
}

export default withRouter(withStyles(s)(Partners));
